function scoreCircle(){
  var $grades = $('.grade');
  $grades.each(function() {
    var $grade = $(this);
    var $foreground = $grade.find('.grade__icon__foreground');
    var scorePercentage = $grade.data('grade-score');
    console.log('::::::::: Score Circle Called :::::::::');
    if(scorePercentage <= 33){
      $grade.css({color:"red"});
    }else{
      if(scorePercentage <= 66){
        $grade.css({color:"orange"})
      }else{
       $grade.css({color:"green"})
      }
    }
    var foregroundCircumference = 2 * Math.PI * parseInt($foreground.attr('r'));
    var foregroundDashOffset = foregroundCircumference - ((foregroundCircumference * scorePercentage) / 100);
      
    $foreground.css('stroke-dasharray', foregroundCircumference);
    $foreground.css('stroke-dashoffset', foregroundCircumference);
    
    setTimeout(function() {
      $grade.addClass('animate');
      $foreground.css('stroke-dashoffset', foregroundDashOffset);
    }, 1000);
  });
}
$(document).ready(scoreCircle);